import axios from "axios.js";
let urls = {
  dashboardData: 'web/dashboard/data',
  loginApi: 'panel/user/login',
  userByRoleApi: 'panel/user/userByRole',
  validateToken: 'web/user/validateUser',
  addHotelManager: 'panel/user/register',
  updateUser: 'web/user/updateUser',
  userById: 'web/user/getUserById',
  removeUser: 'web/user/',
  getLatLong: 'web/hotel/goggleApi',
  hotelList: 'web/hotel/list',
  updateHotel: 'panel/hotel/',
  removeHotel: 'panel/hotel/',
  amenityList: 'panel/amenity/list',
  removeAmenity: 'panel/amenity/',
  uploadAmenityImage: 'panel/amenity/upload',
  uploadHotelImage: 'panel/hotel/upload',
  removeImage: 'panel/hotel/removeImage',
  addAmenity: 'panel/amenity/',
  packageList: 'web/package/list',
  removePackage: 'panel/package/',
  uploadPackageImage: 'panel/package/upload',
  addPackage: 'panel/package/',
  roomList: 'web/room/list',
  removeRoom: 'panel/room/',
  uploadRoomImage: 'panel/room/upload',
  addRoom: 'panel/room/',
  addOnList: 'panel/addOn/list',
  removeAddOn: 'panel/addOn/',
  uploadAddOnImage: 'panel/addOn/upload',
  addAddOn: 'panel/addOn/',
  sliderList: 'web/slider/list',
  removeSlider: 'panel/slider/',
  uploadSliderImage: 'panel/slider/upload',
  addSlider: 'panel/slider/',
  couponList: 'panel/coupon/list',
  removeCoupon: 'panel/coupon/',
  uploadCouponImage: 'panel/coupon/upload',
  addCoupon: 'panel/coupon/',
  couponHistoryList: 'panel/couponhistory/list',
  bookingList: 'panel/bookings/list',
  bookingFilter: 'panel/bookings/filter',
  reviewList: 'web/review/list',
  addReview: 'web/review/',
  roomRecordListApi: 'panel/roomRecord/list',
  contactList: 'panel/contact-us/list',
  roomRecordEdit: 'panel/roomRecord/edit',
};

let api = {
  dashboardData: (body) => axios.get(urls.dashboardData),
  login: (body) => axios.post(urls.loginApi, body),
  userByRole: (body) => axios.post(urls.userByRoleApi, body),
  validate: () => axios.get(urls.validateToken),
  addHotelManager: (body) => axios.post(urls.addHotelManager, body),
  updateUser: (body) => axios.post(urls.updateUser, body),
  getUser: (body) => axios.post(urls.userById, body),
  removeUser: (id) => axios.delete(urls.removeUser + id),
  removeImage: (path) =>
    axios.put(urls.removeImage, {
      filePath: path,
    }),
  hotels: {
    list: (body) => axios.post(urls.hotelList, body),
    get: (id) => axios.get(urls.updateHotel + id),
    add: (body) => axios.post(urls.updateHotel, body),
    update: (body) => axios.put(urls.updateHotel + body._id, body),
    remove: (id) => axios.delete(urls.removeHotel + id),
    imageUpload: (body) => axios.post(urls.uploadHotelImage, body),
    getLatLng: (body) => axios.post(urls.getLatLong, body),
  },
  amenity: {
    list: (body) => axios.post(urls.amenityList, body),
    remove: (id) => axios.delete(urls.removeAmenity + id),
    imageUpload: (body) => axios.post(urls.uploadAmenityImage, body),
    add: (body) => axios.post(urls.addAmenity, body),
    update: (body) => axios.put(urls.addAmenity + body._id, body),
  },
  packages: {
    list: (body) => axios.post(urls.packageList, body),
    get: (id) => axios.get(urls.addPackage + id),
    remove: (id) => axios.delete(urls.removePackage + id),
    imageUpload: (body) => axios.post(urls.uploadPackageImage, body),
    add: (body) => axios.post(urls.addPackage, body),
    update: (body) => axios.put(urls.addPackage + body._id, body),
  },
  rooms: {
    list: (body) => axios.post(urls.roomList, body),
    get: (id) => axios.get(urls.addRoom + id),
    remove: (id) => axios.delete(urls.removeRoom + id),
    imageUpload: (body) => axios.post(urls.uploadRoomImage, body),
    add: (body) => axios.post(urls.addRoom, body),
    update: (body) => axios.put(urls.addRoom + body._id, body),
  },
  roomRecord: {
    roomRecordList: (body) => axios.post(urls.roomRecordListApi, body),
    editRoomRecord: (body) => axios.put(urls.roomRecordEdit, body),
  },
  addOns: {
    list: (body) => axios.post(urls.addOnList, body),
    remove: (id) => axios.delete(urls.removeAddOn + id),
    imageUpload: (body) => axios.post(urls.uploadAddOnImage, body),
    add: (body) => axios.post(urls.addAddOn, body),
    update: (body) => axios.put(urls.addAddOn + body._id, body),
  },
  slider: {
    list: (body) => axios.post(urls.sliderList, body),
    remove: (id) => axios.delete(urls.removeSlider + id),
    imageUpload: (body) => axios.post(urls.uploadSliderImage, body),
    add: (body) => axios.post(urls.addSlider, body),
    update: (body) => axios.put(urls.addSlider + body._id, body),
  },
  coupon: {
    list: (body) => axios.post(urls.couponList, body),
    remove: (id) => axios.delete(urls.removeCoupon + id),
    imageUpload: (body) => axios.post(urls.uploadCouponImage, body),
    add: (body) => axios.post(urls.addCoupon, body),
    update: (body) => axios.put(urls.addCoupon + body._id, body),
  },
  couponHistory:{
    list: (body) => axios.post(urls.couponHistoryList, body),
  },
  booking: {
    list: (body) => axios.post(urls.bookingList, body),
    filter: (data) => axios.post(urls.bookingFilter, data),
  },
  review: {
    list: (body) => axios.post(urls.reviewList, body),
    add: (body) => axios.post(urls.addReview, body),
    update: (body) => axios.put(urls.addReview + body._id, body),
    get: (id) => axios.get(urls.addReview + id),
    remove: (id) => axios.delete(urls.addReview + id),
  },
  contact: {
    list: (body) => axios.post(urls.contactList, body),
  },
};

export default api;
