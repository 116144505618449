import React, {
    createContext,
    useEffect,
    useReducer
} from 'react'
import axios from 'axios.js'
import api from 'api.js'
import {
    MatxLoading
} from 'app/components'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}



const setSession = (accessToken, user) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        if (user) {
            localStorage.setItem('role', user.role.name);
            localStorage.setItem('id', user._id);
        } else {
            localStorage.removeItem('role');
            localStorage.removeItem('id');
        }
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const {
                isAuthenticated,
                user
            } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const {
                user
            } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const {
                user
            } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
})

export const AuthProvider = ({
        children
    }) => {
        const [state, dispatch] = useReducer(reducer, initialState)

        const login = async (email, password) => {
            try {
                const response = await api.login({
                    email,
                    password
                });
                const accessToken = response.data.data.token;
                const user = response.data.data.record;
             

                setSession(accessToken, user)

                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user,
                    },
                })
            } catch (err) {
                alert(err.message);
            }
        }

        const register = async (email, username, password) => {
            const response = await axios.post('/api/auth/register', {
                email,
                username,
                password,
            })

            const {
                accessToken,
                user
            } = response.data

            setSession(accessToken, user)

            dispatch({
                type: 'REGISTER',
                payload: {
                    user,
                },
            })
        }

        const logout = () => {
            setSession(null, null)
            dispatch({
                type: 'LOGOUT'
            })
        }

        useEffect(() => {
            (async () => {
                try {
                    const accessToken = window.localStorage.getItem('accessToken')

                    if (accessToken) {
                        setSession(accessToken, null)
                        const response = await api.validate();
                        const {
                            user
                        } = response.data.data
                        setSession(accessToken, user)
                        

                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: true,
                                user,
                            },
                        })
                    } else {
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: false,
                                user: null,
                            },
                        })
                    }
                } catch (err) {
                    console.error(err)
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            })()
        }, [])

        if (!state.isInitialised) 
            return <MatxLoading />
        

        return ( <AuthContext.Provider value = {
                {
                    ...state,
                    method: 'JWT',
                    login,
                    logout,
                    register,
                }
            }> {
                children
            } </AuthContext.Provider>)
        }

        export default AuthContext