import Loadable from 'app/components/Loadable';
import {
    lazy
} from 'react';

const HotelMangerList = Loadable(lazy(() => import('./HotelMangerList')));
const AddHotelManger = Loadable(lazy(() => import('./AddHotelManger')));
const EditHotelManager = Loadable(lazy(() => import('./EditHotelManager')));
const HotelList = Loadable(lazy(() => import('./HotelList')));
const AddHotel = Loadable(lazy(() => import('./AddHotel')));
const Amenities = Loadable(lazy(() => import('./Amenities')));
const PackageList = Loadable(lazy(() => import('./PackageList')));
const AddPackage = Loadable(lazy(() => import('./AddPackage')));
const AddOn = Loadable(lazy(() => import('./AddOn')));
const Slider = Loadable(lazy(() => import('./Slider')));
const Coupon = Loadable(lazy(() => import('./Coupon')));
const HotelCoupon = Loadable(lazy(() => import('./HotelCoupon')));
const CouponHistory = Loadable(lazy(() => import('./CouponHistory')));
const BookingList = Loadable(lazy(() => import('./BookingList')));
const ReviewList = Loadable(lazy(() => import('./ReviewList')));
const ContactUs = Loadable(lazy(() => import('./ContactUs')));

const pageRoutes = [{
    path: '/pages/managerList',
    element: < HotelMangerList />,
},
{
    path: '/pages/addManager',
    element: < AddHotelManger />,
},
{
    path: '/pages/editManager/:id',
    element: < EditHotelManager />,
},
{
    path: '/pages/hotelList',
    element: < HotelList />,
},
{
    path: '/pages/hotel',
    element: < AddHotel />,
},
{
    path: '/pages/EditHotel/:id',
    element: < AddHotel />,
},
{
    path: '/pages/amenities',
    element: < Amenities />,
},
{
    path: '/pages/packages',
    element: < PackageList />,
},
{
    path: '/pages/addPackage',
    element: < AddPackage />,
},
{
    path: '/pages/editPackage/:id',
    element: < AddPackage />,
},
{
    path: '/pages/addOn/',
    element: < AddOn />,
},
{
    path: '/pages/coupon',
    element: < Coupon />,
},
{
    path: '/pages/hotelcoupon',
    element: <HotelCoupon />,
},
{
    path: '/pages/couponhistory',
    element: < CouponHistory />,
},
{
    path: '/pages/slider/',
    element: < Slider />,
},
{
    path: '/pages/bookings/',
    element: < BookingList />,
},
{
    path: '/pages/reviews/',
    element: < ReviewList />,
},
{
    path: '/pages/contactus',
    element: <ContactUs />
}
];

export default pageRoutes;